import { Controller } from "stimulus";

/**
 * Gets estimated payments for all applicable lease terms that the
 * location may qualify for and sets the values
 */
export default class extends Controller {
  static targets = [
    "locationGuid",
    "merchandiseAmount",
    "prepaidRentAmount",
    "prepaidRentContainer",
    "detailedPrepaidRentContainer",
    "detailPrepaidRent",
    "detailPrepaidRentLabel",
    "locationsAdditionalPaymentsData",
    "prepaidRentMoreThanRenewalPaymentMessage",
    "payFrequency",
    "itemDescription",
    "priceTagDrawer",
    "printTagButton",
    "itemDescriptionError",
    "prepaidRentError",
    "prepaymentSummaryWall",
    "firstRecurringPaymentDetails",
    "prepaidRentDetails",
    "totalRecurringPaymentDetails",
    "firstRecurringPaymentMessage",
    "firstRecurringPayment",
    "prepaidRentAmountDetail",
    "totalRecurringPaymentDetail",
    "leaseTermSelect",
    "ldwCheckBox",
    "benefitsPlusCheckBox",
    "overviewLeaseAmount",
    "recurringRenewalPayment",
    "recurringLeaseDamageWaiverContainer",
    "recurringLeaseDamageWaiver",
    "recurringBenefitsPlusContainer",
    "recurringBenefitsPlus",
    "recurringPaymentTotal",
    "totalCashPrice",
    "totalOfPaymentLabel",
    "totalOfPaymentValue",
    "totalPaymentFrequency",
    "totalNoOfPayments"
  ];

  connect() {
    this.currentGuid = null;
    this.renewalPaymentAmount = "";
    this.prepaidRentAmountIsNumeric = false;
    this.locationsAdditionalPaymentsData = null;
    this.subTotalRecurringPayment = null;
    this.currentResponseData = null;

    this.hideAllLeaseTerms();
    this.togglePrepaidRentContainer();
  }

  togglePrepaidRentContainer() {
    if (!this.hasLocationsAdditionalPaymentsDataTarget) return;

    const locationsAdditionalPaymentsData = JSON.parse(this.locationsAdditionalPaymentsDataTarget.textContent);
    const additionalPaymentsAllowed = locationsAdditionalPaymentsData[0]?.additional_payments_allowed;

    this.currentGuid = locationsAdditionalPaymentsData[0]?.id;
    this.locationsAdditionalPaymentsData = locationsAdditionalPaymentsData[0];

    if (additionalPaymentsAllowed) {
      if (this.hasPrepaidRentContainerTarget) this.toggleContainer(this.prepaidRentContainerTarget, true);
    } else {
      if (this.hasPrepaidRentContainerTarget) this.toggleContainer(this.prepaidRentContainerTarget, false);
    }
  }

  onLocationChange() {
    const selectedLocation = this.locationGuidTarget.value;
    const locationsAdditionalPaymentsData = JSON.parse(this.locationsAdditionalPaymentsDataTarget.textContent);
    const locationData = locationsAdditionalPaymentsData.find(
      (location) => location.id === selectedLocation
    );

    this.locationsAdditionalPaymentsData = locationData;

    if (locationData && locationData.additional_payments_allowed) {
      if (this.hasPrepaidRentContainerTarget) this.toggleContainer(this.prepaidRentContainerTarget, true);
    } else {
      if (this.hasPrepaidRentContainerTarget) this.toggleContainer(this.prepaidRentContainerTarget, false);
    }

    if (this.hasPrepaidRentAmountTarget) this.prepaidRentAmountTarget.value = null;
    this.hideFirstRecurringPaymentDetails();
    this.hideDetailedPrepaidRent();
    this.calculateEstimatedPayments();
  }

  onPaymentFrequencyOrLeaseTermChange() {
    if (this.currentResponseData) this.displayCalculations(this.currentResponseData);

    this.calculateEstimatedPayments();
  }

  /**
   * Validates that the Merchandise Amount is a minimum of 100
   * and a maximum of 6600 to prevent calculating too early
   * @return {bool}
   */
  validateMerchandiseAmount() {
    const amount = this.merchandiseAmountTarget.value;
    const formattedAmount = this.formatMerchandiseAmount(amount);

    this.clearDisplayOnInvalidAmounts(formattedAmount);

    return this.validateMinimumAmount(formattedAmount) &&
           this.validateMaximumAmount(formattedAmount);
  }

  /**
   * Clears results when amount is invalid
   * @param  {float} amount float
   */
  clearDisplayOnInvalidAmounts(amount) {
    if (!this.validateMinimumAmount(amount) || !this.validateMaximumAmount(amount)) {
      this.resetOverviewTargets();
      this.hideAllLeaseTerms();
    }
  }

  clearDisplayOnInvalidAmount() {
    const amount = this.merchandiseAmountTarget.value;

    if (
      amount == "" ||
      !this.validateMinimumAmount(amount) ||
      !this.validateMaximumAmount(amount)
    ) {
      this.resetOverviewTargets();
      this.leaseTermSelectTarget.value = "";
      this.hideAllLeaseTerms();
      this.setPrintButtonState();
    } else {
      this.leaseTermSelectTarget.value = "12_months";
      this.calculateEstimatedPayments();
    }
  }

  clearDisplayOnInvalidPrepaidRent() {
    const merchandiseAmount = this.merchandiseAmountTarget.value;

    if (!this.validateMerchandiseAmount()) {
      this.prepaidRentErrorHandler();

      return;
    }

    const prepaidRentAmount = this.hasPrepaidRentContainerTarget ?  this.prepaidRentAmountTarget.value : "";

    if (this.validatePrepaidRentValue(prepaidRentAmount, 0, merchandiseAmount * 0.4)) {
      if (this.hasPrepaidRentErrorTarget) this.toggleContainer(this.prepaidRentErrorTarget, false);
      this.calculateEstimatedPayments();
    } else {
      this.prepaidRentErrorHandler();
    }

    this.setPrintButtonState();
  }

  /**
   * Resets overview targets text content to "--"
   */
  resetOverviewTargets() {
    const translationPrefix = "users.coworkers.dashboard.payment_calculator";
    const leaseTerm = this.leaseTermSelectTarget.value;

    this.overviewLeaseAmountTarget.textContent = "--";
    this.recurringRenewalPaymentTarget.textContent = "--";
    this.recurringLeaseDamageWaiverTarget.textContent = "--";
    this.recurringBenefitsPlusTarget.textContent = "--";
    this.recurringPaymentTotalTarget.textContent = "--";
    this.totalCashPriceTarget.textContent = "--";

    if (leaseTerm != "") {
      this.totalOfPaymentLabelTarget.textContent = acima.i18n.t(
        `${translationPrefix}.labels.overview.totals.total_of_payment.${leaseTerm}`
      );
    } else {
      this.totalOfPaymentLabelTarget.textContent = acima.i18n.t(
        `${translationPrefix}.labels.overview.totals.total_of_payment.12_months`
      );
    }

    this.hideDetailedPrepaidRent();
    this.hideFirstRecurringPaymentDetails();
    this.prepaidRentErrorHandler();

    this.totalOfPaymentValueTarget.textContent = "--";
    this.totalPaymentFrequencyTarget.textContent = "--";
    this.totalNoOfPaymentsTarget.textContent = "--";
  }

  /**
   * Validates that the Merchandise Amount is a minimum of 100
   * to prevent calculating too early
   * @param  {string} amount value of merchandise amount
   * @return {bool}
   */
  validateMinimumAmount(amount) {
    return amount >= parseInt(this.merchandiseAmountTarget.dataset.minimum);
  }

  /**
   * Validates that the Prepaid Rent Amount is a between
   * a minimum and a maximum provided values
   * @param  {string} prepaidRentAmount value of prepaid rent amount
   * @param  {string} minimum minimum value to compare
   * @param  {string} maximum maximum value to compare
   * @return {bool}
   */
  validatePrepaidRentValue(prepaidRentAmount, minimum, maximum) {
    return prepaidRentAmount <= parseFloat(maximum) && prepaidRentAmount >= parseFloat(minimum);
  }

  /**
   * Validates that the Merchandise Amount is a maximum of 6600
   * to prevent calculating too early
   * @param  {string} amount value of merchandise amount
   * @return {bool}
   */
  validateMaximumAmount(amount) {
    return amount <= parseInt(this.merchandiseAmountTarget.dataset.maximum);
  }

  /**
   * Only allow for desired input, 0-9 & .
   * @param  {string} amount value of merchandise amount
   * @return {float} float value of merchandise amount
   */
  formatMerchandiseAmount(amount) {
    const strippedAmount = amount.replace(/[^0-9.]+/g, "");
    this.merchandiseAmountTarget.value = strippedAmount;

    return parseFloat(strippedAmount);
  }

  /**
   * Validates that the location guid is not blank before processing request
   * @return {bool}
   */
  validateLocationGuid() {
    return this.locationGuidTarget.value !== "";
  }

  /**
   * Validates that the pay frequency is not blank before processing request
   * @return {bool}
   */
  validatePayFrequency() {
    return this.payFrequencyTarget.value !== "";
  }

  /**
   * Validates that the maximum length is 35 characters
   * Also enables/disables the submit button
   * @return {bool}
   */
  validateItemDescriptionLength() {
    const maxLength = 35;
    const label = this.itemDescriptionErrorTarget;

    if (this.itemDescriptionTarget.value.length > maxLength) {
      label.classList.remove("hide");
      return false;
    } else {
      label.classList.add("hide");
      return true;
    }
  }

  /**
   * Validates that the item description is not blank before processing request
   * Also validates that the maximum length is 35 characters
   * @return {bool}
   */
  validateItemDescription() {
    return this.hasItemDescriptionTarget &&
           this.validateItemDescriptionLength() &&
           this.itemDescriptionTarget.value !== "";
  }

  /**
   * Validates that the prepaid rent amount is between 0 and the 40% of the merchandise amount
   * Also show/hide the label error
   */
  prepaidRentErrorHandler() {
    const merchandiseAmount = this.merchandiseAmountTarget.value;
    const prepaidRentAmount = this.hasPrepaidRentContainerTarget ?  this.prepaidRentAmountTarget.value : "";

    const validAmount = this.validatePrepaidRentValue(prepaidRentAmount, 0, merchandiseAmount * 0.4);

    if (validAmount) {
      if (this.hasPrepaidRentErrorTarget) this.toggleContainer(this.prepaidRentErrorTarget, false);
    } else {
      if (this.hasPrepaidRentErrorTarget) this.toggleContainer(this.prepaidRentErrorTarget, true);
    }
  }

  /**
   * Runs all validations for form
   * @return {bool}
   */
  validateForm() {
    const prepaidRentAmountExists = this.hasPrepaidRentAmountTarget;
    const prepaidRentAmount = prepaidRentAmountExists ? this.prepaidRentAmountTarget.value : null;

    this.prepaidRentAmountIsNumeric = prepaidRentAmountExists && !isNaN(prepaidRentAmount) && prepaidRentAmount !== "";

    if (this.prepaidRentAmountIsNumeric) {
      return false;
    }

    return this.validateMerchandiseAmount() &&
           this.validateLocationGuid() &&
           this.validatePayFrequency();
  }

  /**
   * Validates if there are available programs for a given
   * lease term
   * @return {bool}
   */
  validateNoOfPayments() {
    return this.totalNoOfPaymentsTarget.textContent != "--";
  }

  /**
   * Runs all validations for the whole price tag form
   * @return {bool}
   */
  validatePriceTagForm() {
    return this.validateForm() &&
           this.validateItemDescription() &&
           this.validateNoOfPayments();
  }

  /**
   * Finds and replaces all applicable values for calculator
   * @param  {object} calculations returned from API
   */
  displayCalculations(calculations) {
    const translationPrefix = "users.coworkers.dashboard.payment_calculator";
    const isLDWSelected = this.ldwCheckBoxTarget.checked;
    const isBenefitsPlusSelected = this.benefitsPlusCheckBoxTarget.checked;

    let leaseTerm = this.leaseTermSelectTarget.value;
    let leaseTermCalculation = calculations.additional_info[leaseTerm];

    // Reset lease term if null
    if (!leaseTermCalculation) {
      this.resetLeaseTermSelection();
      leaseTerm = this.leaseTermSelectTarget.value;
      leaseTermCalculation = calculations.additional_info[leaseTerm];
    }

    // Set renewal payment amount
    this.renewalPaymentAmount = leaseTermCalculation.renewal_payment_amount;
    this.subTotalRecurringPayment = leaseTermCalculation.renewal_payment_amount;

    const payFrequency = leaseTermCalculation.renewal_payment_frequency;

    // Toggle visibility of containers based on selections
    this.toggleContainer(this.recurringLeaseDamageWaiverContainerTarget, isLDWSelected);
    this.toggleContainer(this.recurringBenefitsPlusContainerTarget, isBenefitsPlusSelected);

    // Update labels and amounts
    this.totalOfPaymentLabelTarget.textContent = acima.i18n.t(
      `${translationPrefix}.labels.overview.totals.total_of_payment.${leaseTerm}`
    );

    this.overviewLeaseAmountTarget.textContent = accounting.formatMoney(
      leaseTermCalculation.merchandise_total, "$", 2
    );

    this.recurringRenewalPaymentTarget.textContent = accounting.formatMoney(
      leaseTermCalculation.renewal_payment_amount, "$", 2
    );

    let totalRecurringPayment = 0;

    // Prepaid rent handling
    const prepaidRentValue = this.hasPrepaidRentAmountTarget ? this.prepaidRentAmountTarget.value : null;

    if (
      this.locationsAdditionalPaymentsData.additional_payments_allowed &&
      prepaidRentValue === "" &&
      !isNaN(prepaidRentValue)
    ) {
      // If prepaidRentAmount is empty and additional payments are allowed
      this.hideFirstRecurringPaymentDetails();
      this.hideDetailedPrepaidRent();

      totalRecurringPayment = this.renewalPaymentAmount;
    } else {
      if (prepaidRentValue === "") {
        this.hideFirstRecurringPaymentDetails();
        this.hideDetailedPrepaidRent();
      } else {
        const prepaidRentAmountFloat = parseFloat(prepaidRentValue);

        if (prepaidRentAmountFloat <= this.renewalPaymentAmount) {
          totalRecurringPayment = parseFloat(leaseTermCalculation.prepayment_remaining_payment_amount);

          this.showFirstRecurringPaymentDetails(prepaidRentAmountFloat, totalRecurringPayment);
        } else {
          this.showDetailedPrepaidRent(
            translationPrefix,
            prepaidRentAmountFloat,
            leaseTermCalculation.number_of_renewal_payments,
            leaseTermCalculation.pro_rate_payment_amount
          );

          totalRecurringPayment = parseFloat(leaseTermCalculation.effective_payment_amount);
        }
      }
    }

    // Benefits Plus calculations
    if (leaseTermCalculation.benefits_plus_fee_amount != null) {
      this.recurringBenefitsPlusTarget.textContent = accounting.formatMoney(
        leaseTermCalculation.benefits_plus_fee_amount, "$", 2
      );

      totalRecurringPayment += leaseTermCalculation.benefits_plus_fee_amount;
      this.subTotalRecurringPayment += leaseTermCalculation.benefits_plus_fee_amount;

      if (prepaidRentValue !== "") {
        const prepaidRentAmountFloat = parseFloat(prepaidRentValue);

        if (prepaidRentAmountFloat <= this.renewalPaymentAmount) {
          this.showFirstRecurringPaymentDetails(prepaidRentAmountFloat, totalRecurringPayment);
        }
        else {
          this.showDetailedPrepaidRent(
            translationPrefix,
            prepaidRentAmountFloat,
            leaseTermCalculation.number_of_renewal_payments,
            leaseTermCalculation.pro_rate_payment_amount
          );
        }
      }
    } else {
      this.recurringBenefitsPlusTarget.textContent = "--";
    }

    // LDW calculations
    if (leaseTermCalculation.ldw_fee_amount != null) {
      this.recurringLeaseDamageWaiverTarget.textContent = accounting.formatMoney(
        leaseTermCalculation.ldw_fee_amount, "$", 2
      );

      totalRecurringPayment += leaseTermCalculation.ldw_fee_amount;
      this.subTotalRecurringPayment += leaseTermCalculation.ldw_fee_amount;

      if (prepaidRentValue !== "") {
        const prepaidRentAmountFloat = parseFloat(prepaidRentValue);

        if (prepaidRentAmountFloat <= this.renewalPaymentAmount) {
          this.showFirstRecurringPaymentDetails(prepaidRentAmountFloat, totalRecurringPayment);
        }
        else {
          this.showDetailedPrepaidRent(
            translationPrefix,
            prepaidRentAmountFloat,
            leaseTermCalculation.number_of_renewal_payments,
            leaseTermCalculation.pro_rate_payment_amount
          );
        }
      }
    } else {
      this.recurringLeaseDamageWaiverTarget.textContent = "--";
    }

    // Set total recurring payment and other amounts
    this.recurringPaymentTotalTarget.textContent = accounting.formatMoney(this.subTotalRecurringPayment, "$", 2);
    this.totalCashPriceTarget.textContent = accounting.formatMoney(leaseTermCalculation.acima_cash_price, "$", 2);
    this.totalOfPaymentValueTarget.textContent = accounting.formatMoney(leaseTermCalculation.total_of_payments, "$", 2);
    this.totalPaymentFrequencyTarget.textContent = acima.i18n.t(`${translationPrefix}.pay_frequency.${payFrequency}`);
    this.totalNoOfPaymentsTarget.textContent = leaseTermCalculation.number_of_renewal_payments;

    this.setPrintButtonState();
    this.hideUnsupportedPrograms(calculations);
  }

  /**
   * Toggles visibility of a container.
   */
  toggleContainer(container, isVisible) {
    if (isVisible) {
      $(container).removeClass("hide");
    } else {
      $(container).addClass("hide");
    }
  }

  showFirstRecurringPaymentDetails(prepaidRentAmountFloat, totalRecurringPayment) {
    if (this.hasPrepaymentSummaryWallTarget) this.toggleContainer(this.prepaymentSummaryWallTarget, true);
    if (this.hasFirstRecurringPaymentDetailsTarget) this.toggleContainer(this.firstRecurringPaymentDetailsTarget, true);
    if (this.hasPrepaidRentDetailsTarget) this.toggleContainer(this.prepaidRentDetailsTarget, true);
    if (this.hasTotalRecurringPaymentDetailsTarget) this.toggleContainer(this.totalRecurringPaymentDetailsTarget, true);
    if (this.hasFirstRecurringPaymentMessageTarget) this.toggleContainer(this.firstRecurringPaymentMessageTarget, true);

    this.hideDetailedPrepaidRent();
    if (this.hasTotalRecurringPaymentDetailTarget) this.totalRecurringPaymentDetailTarget.textContent = accounting.formatMoney(this.subTotalRecurringPayment, "$", 2);
    if (this.hasPrepaidRentAmountDetailTarget) this.prepaidRentAmountDetailTarget.textContent = accounting.formatMoney(prepaidRentAmountFloat, "$", 2);
    if (this.hasFirstRecurringPaymentTarget) this.firstRecurringPaymentTarget.textContent = accounting.formatMoney(totalRecurringPayment, "$", 2);
  }

  hideFirstRecurringPaymentDetails() {
    if (this.hasPrepaymentSummaryWallTarget) this.toggleContainer(this.prepaymentSummaryWallTarget, false);
    if (this.hasFirstRecurringPaymentDetailsTarget) this.toggleContainer(this.firstRecurringPaymentDetailsTarget, false);
    if (this.hasPrepaidRentDetailsTarget) this.toggleContainer(this.prepaidRentDetailsTarget, false);
    if (this.hasTotalRecurringPaymentDetailsTarget) this.toggleContainer(this.totalRecurringPaymentDetailsTarget, false);
    if (this.hasFirstRecurringPaymentMessageTarget) this.toggleContainer(this.firstRecurringPaymentMessageTarget, false);

    if (this.hasFirstRecurringPaymentTarget) this.firstRecurringPaymentTarget.textContent = "--";
    if (this.hasPrepaidRentAmountDetailTarget) this.prepaidRentAmountDetailTarget.textContent = "--";
    if (this.hasTotalRecurringPaymentDetailTarget) this.totalRecurringPaymentDetailTarget.textContent = "--";
  }

  showDetailedPrepaidRent(translationPrefix, prepaidRentAmountFloat, numberOfRenewalPayments, proratedPaymentAmount) {
    if (this.hasDetailedPrepaidRentContainerTarget) this.toggleContainer(this.detailedPrepaidRentContainerTarget, true);
    if (this.hasPrepaidRentMoreThanRenewalPaymentMessageTarget) this.toggleContainer(this.prepaidRentMoreThanRenewalPaymentMessageTarget, true);
    this.hideFirstRecurringPaymentDetails();

    if (this.hasDetailPrepaidRentLabelTarget) {
      this.detailPrepaidRentLabelTarget.textContent = acima.i18n.t(
        `${translationPrefix}.labels.overview.recurring_payments.detailed_prepaid_rent`,
        {
          amount: accounting.formatMoney(prepaidRentAmountFloat, "$", 2),
          payments_number: numberOfRenewalPayments,
        }
      );
    }

    if (this.hasDetailPrepaidRentTarget) {
      this.detailPrepaidRentTarget.textContent = accounting.formatMoney(
        proratedPaymentAmount, "$", 2
      );
    }
  }

  hideDetailedPrepaidRent() {
    if (this.hasDetailedPrepaidRentContainerTarget) this.toggleContainer(this.detailedPrepaidRentContainerTarget, false);
    if (this.hasPrepaidRentMoreThanRenewalPaymentMessageTarget) this.toggleContainer(this.prepaidRentMoreThanRenewalPaymentMessageTarget, false);

    if (this.hasDetailPrepaidRentLabelTarget) this.detailPrepaidRentLabelTarget.textContent = "--";
    if (this.hasDetailPrepaidRentTarget) this.detailPrepaidRentTarget.textContent = "--";
  }

  /** Hide option from select list.
   * @param {Object} select target.
   * @param {String} value of the option.
   * @param {Boolean} true/false.
   */
  hideExtendedLeaseTerm(selectTarget, optionValue, hideTerm) {
    const targetOption = selectTarget.querySelector("option[value='" + optionValue + "']");
    hideTerm ? targetOption.classList.add("hide") : targetOption.classList.remove("hide");
  };

  /**
   * Hide all lease term options by default except for a blank option
   */
  hideAllLeaseTerms() {
    const leaseTermSelectOptions = [...this.leaseTermSelectTarget.options];
    for (const termOption of leaseTermSelectOptions) {
      if (termOption.value != "") {
        this.hideExtendedLeaseTerm(this.leaseTermSelectTarget, termOption.value, true);
      }
    }
  }

  /**
   * Reset lease term select field
  */
  resetLeaseTermSelection() {
    this.leaseTermSelectTarget.value = "12_months";
    this.hideAllLeaseTerms();
  }

  /**
   * hides all unsupported lease terms
   * @param {object} calculations
  */
  hideUnsupportedPrograms(calculations) {
    const leaseTermSelectOptions = [...this.leaseTermSelectTarget.options].map(o => o.text);

    for (const leaseTerm of leaseTermSelectOptions) {
      const formattedTerm = leaseTerm.toLowerCase().replace(" ", "_");
      const isLeaseTermEmpty = calculations.additional_info[formattedTerm] == null;
      this.hideExtendedLeaseTerm(this.leaseTermSelectTarget, formattedTerm, isLeaseTermEmpty);
    }
  }

  /**
   * Gets applicable data for params and sends request to the
   * server side lease payment estimates controller and saves response
   */
  calculateEstimatedPayments() {
    if (!this.validateForm() && !this.prepaidRentAmountIsNumeric) {
      return;
    }

    const prepaid_rent_amount = this.hasPrepaidRentContainerTarget ? this.prepaidRentAmountTarget.value : ""

    const params = new URLSearchParams(
      {
        guid: this.locationGuidTarget.value,
        merchandise_amount: this.merchandiseAmountTarget.value,
        prepaid_rent_amount: prepaid_rent_amount,
        renewal_payment_amount: this.renewalPaymentAmount,
        pay_frequency: this.payFrequencyTarget.value,
        ldw_enabled: this.ldwCheckBoxTarget.checked,
        benefits_plus_enabled: this.benefitsPlusCheckBoxTarget.checked
      }
    );

    const origin = new URL(window.location).origin;
    const requestUrl = new URL(origin + this.data.get("url"));
    const fetchWith = window.acima.fetchInit({ method: "GET" });

    requestUrl.search = params;

    const handleSuccessResponse = function(response) {
      if (response.success === true) {
        this.currentResponseData = response.data;
        this.displayCalculations(response.data);
      }
    }.bind(this);

    const handleErrorResponse = function(error) {
      console.error(error);
    }.bind(this);

    fetch(requestUrl.toString(), fetchWith)
      .then(response => response.json())
      .then(handleSuccessResponse)
      .catch(handleErrorResponse);
  }

  /**
   * enables and disables the print tag button depending on whether
   * enough data is present.
   */
  setPrintButtonState() {
    const button = this.printTagButtonTarget;

    if (
      this.hasPrepaidRentContainerTarget &&
      this.prepaidRentAmountTarget.value &&
      !isNaN(this.prepaidRentAmountTarget.value)
    ) {
      button.classList.add("disabled");
    } else if (this.validatePriceTagForm()) {
      button.classList.remove("disabled");
    } else {
      button.classList.add("disabled");
    }
  }

  /**
   * Prevents form submission unless Price Tag form is valid
   * @param {event} event checks for Enter keydown
   */
  preventFormSubmission(event) {
    if (event.keyCode === 13) {
      if (!this.validatePriceTagForm()) {
        event.preventDefault();
      }
    }
  }
}
